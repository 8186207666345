import React, { Component, Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'

class Impressum extends Component {
  render() {
    return (
      <Fragment>
        <Container>
        <Row>
        <div><h4>Impressum</h4></div>
                <div >
                    <h4>Angaben gem&auml;&szlig; &sect; 5 TMG:</h4>
                    <p>
                        Flecken Coppenbr&uuml;gge<br/>
                        Schlossstra&szlig;e 2<br/>
                        31863 Coppenbr&uuml;gge
                    </p>

                    <h4>Vertreten durch:</h4>
                    <p>Herrn Bürgermeister Hans-Ulrich Peschka</p>

                    <h4>Kontakt:</h4>
                    <table>
                      <tbody>
                        <tr><td>Telefon:</td><td>05156 / 7819 0</td></tr>
                        <tr><td>Telefax:</td><td>05156 / 78 19 40</td></tr>
                        <tr><td>E-Mail:</td><td>flecken@coppenbruegge.de<br/>rauer@coppenbruegge.de</td></tr>
                        </tbody>
                    </table>

                    <h4>Verantwortlich f&uuml;r den Inhalt nach &sect; 55 Abs. 2 RStV:</h4>
                    <div>
                        <p>Redakteur und Datenschutzkoordinator:<br/>
                            Lars Eickstädt<br/>
                            Telefon: +49 (0) 5156 7819-10<br/>
                            E-Mail: eickstaedt@coppenbruegge.de<br/>
                        </p>
                    </div>

                    <h4>Datenschutzbeauftragter:</h4>
                    <p>
                        Torsten Knöller, Kommunale Datenverarbeitung Oldenburg
                        Telefon: +49 (0) 441 9714-159
                        E-Mail: knoeller@kdo.de
                    </p>

                    <h4>Landesbeauftragter für den Datenschutz:</h4>
                    <p>www.lfd.niedersachsen.de</p>



                    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br/>
                        <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a><br/>
                        Unsere E-Mail-Adresse finden Sie oben im Impressum.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Wir sind bereit aber nicht verpflichtet, an Streitbeilegungsverfahren vor
                        einer Verbraucherschlichtungsstelle teilzunehmen.
                    </p>

                    <h4>Haftung f&uuml;r Inhalte</h4>
                    <p>
                        Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1
                        TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                        verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter
                        jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde
                        Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die
                        auf eine rechtswidrige T&auml;tigkeit hinweisen.
                    </p> 
                    <p>Verpflichtungen zur
                        Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist
                        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                        m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                        wir diese Inhalte umgehend entfernen.
                    </p>

                    <h4>Haftung f&uuml;r Links</h4>
                    <p>
                        Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren
                        Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese
                        fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte
                        der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
                        Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                        Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft.
                        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    </p>
                    <p>
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
                        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                        entfernen.
                    </p>

                    <h4>Urheberrecht</h4>
                    <p>
                        Die durch die Seitenbetreiber
                        erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                        Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede
                        Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
                        bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                        Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten,
                        nicht kommerziellen Gebrauch gestattet.</p> <p>Soweit die Inhalte auf dieser
                        Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                        bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Inhalte umgehend
                        entfernen.
                    </p>
                    <p>&nbsp;</p>

                    <h3>Datenschutzerkl&auml;rung:</h3>
                    <h4>Datenschutz</h4> <p>Die Betreiber
                        dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir
                        behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
                        gesetzlichen Datenschutzvorschriften sowie dieser
                        Datenschutzerkl&auml;rung.</p> <p>Die Nutzung unserer Webseite ist in der
                        Regel ohne Angabe personenbezogener Daten m&ouml;glich. Soweit auf unseren
                        Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-
                        Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich, stets auf
                        freiwilliger Basis. Diese Daten werden ohne Ihre ausdr&uuml;ckliche
                        Zustimmung nicht an Dritte weitergegeben.</p> <p>Wir weisen darauf hin, dass
                        die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                        Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten
                        vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p><p>&nbsp;</p>
                    <h4>Cookies</h4> <p>Die Internetseiten verwenden teilweise so genannte
                        Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
                        keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
                        effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf
                        Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p> <p>Die
                        meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“.
                        Sie werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Andere Cookies
                        bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese l&ouml;schen.
                        Diese Cookies erm&ouml;glichen es uns, Ihren Browser beim n&auml;chsten
                        Besuch wiederzuerkennen.</p> <p>Sie k&ouml;nnen Ihren Browser so einstellen,
                        dass Sie &uuml;ber das Setzen von Cookies informiert werden und Cookies nur
                        im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle
                        oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
                        Cookies beim Schlie&szlig;en des Browser aktivieren. Bei der Deaktivierung
                        von Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt
                        sein.</p><p>&nbsp;</p> <h4>Server-Log-Files</h4> <p>Der Provider der Seiten
                        erhebt und speichert automatisch Informationen in so genannten Server-Log
                        Files, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</p>
                    <ul> <li>Browsertyp und Browserversion</li> <li>verwendetes
                            Betriebssystem</li> <li>Referrer URL</li> <li>Hostname des zugreifenden
                            Rechners</li> <li>Uhrzeit der Serveranfrage</li> </ul> <p><br />Diese Daten
                        sind nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser
                        Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor,
                        diese Daten nachtr&auml;glich zu pr&uuml;fen, wenn uns konkrete Anhaltspunkte
                        f&uuml;r eine rechtswidrige Nutzung bekannt werden.</p><p>&nbsp;</p>
                    <h4>Kontaktformular</h4> <p>Wenn Sie uns per Kontaktformular Anfragen
                        zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der
                        von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und
                        f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
                        wir nicht ohne Ihre Einwilligung weiter.</p><p>&nbsp;</p>
                    <h4>Newsletterdaten</h4> <p>Wenn Sie den auf der Webseite angebotenen
                        Newsletter beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-
                        Adresse sowie Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten,
                        dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang
                        des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese
                        Daten verwenden wir ausschlie&szlig;lich f&uuml;r den Versand der
                        angeforderten Informationen und geben sie nicht an Dritte weiter.</p> <p>Die
                        erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie
                        deren Nutzung zum Versand des Newsletters k&ouml;nnen Sie jederzeit
                        widerrufen, etwa &uuml;ber den "Austragen"-Link im
                        Newsletter.</p><p>&nbsp;</p> <h4>Datenschutzerkl&auml;rung f&uuml;r die
                        Nutzung von Google Analytics</h4>
                      <p>Diese Website nutzt Funktionen des
                        Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600
                        Amphitheatre Parkway Mountain View, CA 94043, USA.
                      </p> 
                      <p>Google Analytics
                        verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer
                        gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
                        erm&ouml;glichen. Die durch den Cookie erzeugten Informationen &uuml;ber Ihre
                        Benutzung dieser Website werden in der Regel an einen Server von Google in
                        den USA &uuml;bertragen und dort gespeichert.
                      </p>
                      <p>Mehr Informationen zum
                        Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
                        Datenschutzerkl&auml;rung von Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.</a>
                      </p>
                      <p><strong>Browser Plugin</strong></p>
                      <p>Sie k&ouml;nnen die Speicherung der
                        Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
                        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                        gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website
                        vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber
                        hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der
                        Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                        Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem
                        folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren:
                        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?</a>
                      </p>
                      <p><strong>Widerspruch gegen Datenerfassung</strong></p> 
                      <p>Sie k&ouml;nnen
                        die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf
                        folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung
                        Ihrer Daten bei zuk&uuml;nftigen Besuchen dieser Website verhindert: 
                        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">Google Analytics
                            deaktivieren</a>
                      </p>
                      <p><strong>Auftragsdatenverarbeitung</strong></p>
                      <p>Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen
                        und setzen die strengen Vorgaben der deutschen Datenschutzbeh&ouml;rden bei
                        der Nutzung von Google Analytics vollst&auml;ndig um.
                      </p> 
                      <p><strong>IPAnonymisierung</strong></p> 
                      <p>Wir nutzen die Funktion &quot;Aktivierung der
                        IP-Anonymisierung&quot; auf dieser Webseite. Dadurch wird Ihre IP-Adresse von
                        Google jedoch innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder
                        in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen
                        Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle
                        IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort
                        gek&uuml;rzt. Im Auftrag des Betreibers dieser Website wird Google diese
                        Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
                        &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit
                        der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
                        gegen&uuml;ber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
                        Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit
                        anderen Daten von Google zusammengef&uuml;hrt.
                      </p>
                      <p>&nbsp;</p>
                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Piwik</h4> 
                      <p>Diese Website benutzt den Open Source Webanalysedienst Piwik. Piwik verwendet so
                        genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert
                        werden und die eine Analyse der Benutzung der Website durch Sie
                        erm&ouml;glichen. Dazu werden die durch den Cookie erzeugten Informationen
                        &uuml;ber die Benutzung dieser Website auf unserem Server gespeichert. Die IPAdresse
                        wird vor der Speicherung anonymisiert.
                      </p>
                      <p>Die durch den Cookie
                        erzeugten Informationen &uuml;ber die Benutzung dieser Website werden nicht
                        an Dritte weitergegeben. Sie k&ouml;nnen die Speicherung der Cookies durch
                        eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
                        Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
                        s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen
                        k&ouml;nnen.
                      </p>
                      <p>Wenn Sie mit der Speicherung und Nutzung Ihrer Daten
                        nicht einverstanden sind, k&ouml;nnen Sie die Speicherung und Nutzung hier
                        deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie
                        hinterlegt der verhindert, dass Piwik Nutzungsdaten speichert. Wenn Sie Ihre
                        Cookies l&ouml;schen hat dies zur Folge, dass auch das Piwik Opt-Out-Cookie
                        gel&ouml;scht wird. Das Opt-Out muss bei einem erneuten Besuch unserer Seite
                        wieder aktiviert werden.
                      </p>
                    <p>&nbsp;</p>
                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Facebook-Plugins (Like-Button)</h4>
                    <p>
                        Auf unseren
                        Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc.,
                        1 Hacker Way, Menlo Park, California 94025, USA, integriert. Die Facebook-
                        Plugins erkennen Sie an dem Facebook-Logo oder dem "Like-Button"
                        ("Gef&auml;llt mir") auf unserer Seite. Eine &Uuml;bersicht &uuml;ber die
                        Facebook-Plugins finden Sie hier: <a href="http://developers.facebook.com/docs/plugins/">http://developers.facebook.com/docs/</a>
                    </p>
                    <p>Wenn Sie unsere Seiten besuchen, wird &uuml;ber das Plugin eine direkte
                        Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
                        Facebook erh&auml;lt dadurch die Information, dass Sie mit Ihrer IP-Adresse
                        unsere Seite besucht haben. Wenn Sie den Facebook "Like-Button" anklicken
                        w&auml;hrend Sie in Ihrem Facebook-Account eingeloggt sind, k&ouml;nnen Sie
                        die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann
                        Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen
                        darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
                        &uuml;bermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere
                        Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Facebook
                        unter <a href="http://de-de.facebook.com/policy.php">http://dede.
                            facebook.com/policy.php</a>.
                    </p>
                    <p>Wenn Sie nicht w&uuml;nschen, dass
                        Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann,
                        loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
                    </p>
                    <p>&nbsp;</p>

                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Twitter</h4>
                    <p>
                        Auf
                        unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese
                        Funktionen werden angeboten durch die Twitter Inc., 1355 Market Street, Suite
                        900, San Francisco, CA 94103, USA. Durch das Benutzen von Twitter und der
                        Funktion "Re-Tweet" werden die von Ihnen besuchten Webseiten mit Ihrem
                        Twitter-Account verkn&uuml;pft und anderen Nutzern bekannt gegeben. Dabei
                        werden auch Daten an Twitter &uuml;bertragen. Wir weisen darauf hin, dass wir
                        als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten
                        Daten sowie deren Nutzung durch Twitter erhalten. Weitere Informationen
                        hierzu finden Sie in der Datenschutzerkl&auml;rung von Twitter unter <a
                                href="http://twitter.com/privacy">http://twitter.com/privacy</a>.
                    </p>
                    <p>Ihre Datenschutzeinstellungen bei Twitter k&ouml;nnen Sie in den Konto-
                        Einstellungen unter: <a
                                href="http://twitter.com/account/settings">http://twitter.com/account/settings</a>
                        &auml;ndern.
                    </p>
                    <p>&nbsp;</p>

                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Google+</h4>
                    <p>
                        Unsere Seiten nutzen Funktionen von Google+.
                        Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA
                        94043, USA.
                    </p>
                    <p>Erfassung und Weitergabe von Informationen: Mithilfe der
                        Google+-Schaltfl&auml;che k&ouml;nnen Sie Informationen weltweit
                        ver&ouml;ffentlichen. &Uuml;ber die Google+-Schaltfl&auml;che erhalten Sie
                        und andere Nutzer personalisierte Inhalte von Google und unseren Partnern.
                        Google speichert sowohl die Information, dass Sie f&uuml;r einen Inhalt +1
                        gegeben haben, als auch Informationen &uuml;ber die Seite, die Sie beim
                        Klicken auf +1 angesehen haben. Ihre +1 k&ouml;nnen als Hinweise zusammen mit
                        Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in
                        Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf
                        Websites und Anzeigen im Internet eingeblendet werden.
                    </p>
                    <p>Google zeichnet
                        Informationen &uuml;ber Ihre +1-Aktivit&auml;ten auf, um die Google-Dienste
                        f&uuml;r Sie und andere zu verbessern. Um die Google+-Schaltfl&auml;che
                        verwenden zu k&ouml;nnen, ben&ouml;tigen Sie ein weltweit sichtbares,
                        &ouml;ffentliches Google-Profil, das zumindest den f&uuml;r das Profil
                        gew&auml;hlten Namen enthalten muss. Dieser Name wird in allen Google-
                        Diensten verwendet. In manchen F&auml;llen kann dieser Name auch einen
                        anderen Namen ersetzen, den Sie beim Teilen von Inhalten &uuml;ber Ihr Google-
                        Konto verwendet haben. Die Identit&auml;t Ihres Google-Profils kann Nutzern
                        angezeigt werden, die Ihre E-Mail-Adresse kennen oder &uuml;ber andere
                        identifizierende Informationen von Ihnen verf&uuml;gen.
                    </p>
                    <p>Verwendung der
                        erfassten Informationen: Neben den oben erl&auml;uterten Verwendungszwecken
                        werden die von Ihnen bereitgestellten Informationen gem&auml;&szlig; den
                        geltenden Google-Datenschutzbestimmungen genutzt. Google ver&ouml;ffentlicht
                        m&ouml;glicherweise zusammengefasste Statistiken &uuml;ber die +1-
                        Aktivit&auml;ten der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie
                        etwa Publisher, Inserenten oder verbundene Websites.
                    </p>
                    <p>&nbsp;</p>

                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Instagram</h4>
                    <p>
                        Auf
                        unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese
                        Funktionen werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo
                        Park, CA, 94025, USA integriert. Wenn Sie in Ihrem Instagram-Account
                        eingeloggt sind k&ouml;nnen Sie durch Anklicken des Instagram-Buttons die
                        Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken. Dadurch kann
                        Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen
                        darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
                        u?bermittelten Daten sowie deren Nutzung durch Instagram erhalten.
                    </p>
                    <p>Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung
                        von Instagram: <a href="http://instagram.com/about/legal/privacy/">http://instagram.com/about/legal/privacy/</a>
                    </p>

                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Pinterest</h4>
                    <p>
                        Auf
                        unserer Seite verwenden wir Social Plugins des sozialen Netzwerkes Pinterest,
                        das von der Pinterest Inc., 808 Brannan Street San Francisco, CA 94103-490,
                        USA ("Pinterest") betrieben wird. Wenn Sie eine Seite aufrufen, die ein
                        solches Plugin enth&auml;lt, stellt Ihr Browser eine direkte Verbindung zu
                        den Servern von Pinterest her. Das Plugin &uuml;bermittelt dabei
                        Protokolldaten an den Server von Pinterest in die USA. Diese Protokolldaten
                        enthalten m&ouml;glicherweise Ihre IP-Adresse, die Adresse der besuchten
                        Websites, die ebenfalls Pinterest-Funktionen enthalten, Art und Einstellungen
                        des Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von
                        Pinterest sowie Cookies.
                    </p>
                    
                    <p>Weitere Informationen zu Zweck, Umfang und
                        weiterer Verarbeitung und Nutzung der Daten durch Pinterest sowie Ihre
                        diesbez&uuml;glichen Rechte und M&ouml;glichkeiten zum Schutz Ihrer
                        Privatsph&auml;re finden Sie in den den Datenschutzhinweisen von Pinterest:
                        <a href="https://about.pinterest.com/de/privacypolicy">
                            https://about.pinterest.com/de/privacy-policy</a>
                    </p><p>&nbsp;</p>

                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Tumblr</h4>
                    <p>Unsere
                        Seiten nutzen Schaltfl&auml;chen des Dienstes Tumblr. Anbieter ist die
                        Tumblr, Inc., 35 East 21st St, 10th Floor, New York, NY 10010, USA. Diese
                        Schaltfl&auml;chen erm&ouml;glichen es Ihnen, einen Beitrag oder eine Seite
                        bei Tumblr zu teilen oder dem Anbieter bei Tumblr zu folgen. Wenn Sie eine
                        unserer Webseiten mit Tumblr-Button aufrufen, baut der Browser eine direkte
                        Verbindung mit den Servern von Tumblr auf. Wir haben keinen Einfluss auf den
                        Umfang der Daten, die Tumblr mit Hilfe dieses Plugins erhebt und
                        &uuml;bermittelt. Nach aktuellem Stand werden die IP-Adresse des Nutzers
                        sowie die URL der jeweiligen Webseite &uuml;bermittelt.
                    </p>
                    
                    <p>Weitere
                        Informationen hierzu finden sich in der Datenschutzerkl&auml;rung von Tumblr
                        unter <a href="http://www.tumblr.com/policy/de/privacy">http://www.tumblr.com/policy/de/privacy</a>
                    </p>
                    <p>&nbsp;</p>

                    <h4>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von YouTube</h4>
                    <p>
                        Unsere
                        Webseite nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber
                        der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
                        Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten
                        besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei
                        wird dem Youtube-Server mitgeteilt, welche unserer Seiten Sie besucht
                        haben.</p> <p>Wenn Sie in Ihrem YouTube-Account eingeloggt sind
                        erm&ouml;glichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
                        pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem
                        Sie sich aus Ihrem YouTube-Account ausloggen.
                    </p>
                    <p>Weitere Informationen
                        zum Umgang von Nutzerdaten finden Sie in der Datenschutzerkl&auml;rung von
                        YouTube unter: <a href="https://www.google.de/intl/de/policies/privacy">https://www.google.de/intl/de/policies/</a>
                    </p>
                    <p>&nbsp;</p>

                    <h4>Kommentarfunktion auf dieser Webseite</h4>
                    <p>
                        F&uuml;r die
                        Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben
                        zum Zeitpunkt der Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn
                        Sie nicht anonym posten, der von Ihnen gew&auml;hlte Nutzername
                        gespeichert.</p> <p><strong>Speicherung der IP Adresse</strong></p> <p>Unsere
                        Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare
                        verfassen. Da wir Kommentare auf unserer Seite nicht vor der Freischaltung
                        pr&uuml;fen, ben&ouml;tigen wir diese Daten, um im Falle von
                        Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser
                        vorgehen zu k&ouml;nnen.
                    </p>
                    <p><strong>Abonnieren von Kommentaren</strong></p> 
                    <p>Als Nutzer der Seite k&ouml;nnen Sie nach einer
                        Anmeldung Kommentare abonnieren. Sie erhalten eine Best&auml;tigungsemail, um
                        zu pr&uuml;fen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie
                        k&ouml;nnen diese Funktion jederzeit &uuml;ber einen Link in den Info-Mails
                        abbestellen.
                    </p>
                    <p>&nbsp;</p>
                    <h4>SSL-Verschl&uuml;sselung</h4>
                    <p>Diese Seite nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der
                        &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
                        Sie an uns als Seitenbetreiber senden, eine SSL-Verschl&uuml;sselung. Eine
                        verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des
                        Browsers von &quot;http://&quot; auf &quot;https://&quot; wechselt und an dem
                        Schloss-Symbol in Ihrer Browserzeile.
                    </p>
                    <p>Wenn die SSL
                        Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns
                        &uuml;bermitteln, nicht von Dritten mitgelesen werden.
                    </p>
                    <p>&nbsp;</p>

                    <h4>Auskunft, L&ouml;schung, Sperrung</h4>
                      <p>
                        Sie haben jederzeit das Recht
                        auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen
                        Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung
                        sowie ein Recht auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten.
                        Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen
                        Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
                        wenden.
                      </p>
                      <p>&nbsp;</p>
                    <h4>Widerspruch Werbe-Mails</h4>
                      <p>Der Nutzung von
                        im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur
                        &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und
                        Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten
                        behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten
                        Zusendung von Werbeinformationen, etwa durch Spam-E-Mails,
                        vor.
                      </p>
                      <p>&nbsp;</p>

                    <p>
                      Quelle:<br />
                        <a href="https://www.e-recht24.de/musterdatenschutzerklaerung.html">https://www.e-recht24.de/musterdatenschutzerklaerung.html</a><br />
                        <a href="https://www.e-recht24.de">e-recht24.de</a>
                    </p>

                </div>
          
        </Row>
        </Container>
      </Fragment> 
    )
  }
}

export default Impressum