import React, { Component, Fragment } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Navbar, Container } from 'react-bootstrap';
import MainLogo from '../../assets/images/cube.svg';
import MegaMenuMobile from '../home/MegaMenuMobile';
import Menus_Bar from '../../assets/images/menus_bar.png';

class NavMenuMobile extends Component {

  constructor(){
    super();
    this.state={
      SideNavState:'sideNavClose',
      ContentOverlayState:'ContentOverlayClose'
    }
  }

  MenuBarClickHandler=()=>{
    this.SideNavOpenClose();

  }

  ContentOverlayClickHandler=()=>{
    this.SideNavOpenClose();

  }

  SideNavOpenClose=()=>{
    let SideNavState = this.state.SideNavState;
    //let ContentOverlayState = this.state.ContentOverlayState
    if(SideNavState==='sideNavOpen'){
      this.setState({
        SideNavState:'sideNavClose',
        ContentOverlayState:'ContentOverlayClose'
      })
    }else{
      this.setState({
        SideNavState:'sideNavOpen',
        ContentOverlayState:'ContentOverlayOpen'
      })
    }

  }

  render() {
    return (
      <Fragment>
        <div className='TopSectionDown'>
          
            <Container fluid={'true'} className="fixed-top shadow-sm p-2 mb-0 bg-white">
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                <img className='bar-img' onClick={this.MenuBarClickHandler} src={Menus_Bar} alt='Menu bar icon' />
                  <Navbar.Brand href="#home"><img className='nav-logo' src={MainLogo} alt='Logo'/></Navbar.Brand>
                  <button className='cart-btn'><i className='fa fa-shopping-cart'></i> 3 Items</button>
                </Col>
                
              </Row>

              
            </Container>
            <div className={this.state.SideNavState}>
              <MegaMenuMobile />             
            </div>
            <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverlayState}>

            </div>
          
        </div>

      </Fragment>
    )
  }
}

export default NavMenuMobile