import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import HomePage from '../pages/HomePage'
import UserLoginPage from '../pages/UserLoginPage'
import ContactPage from '../pages/ContactPage'
import AgbPage from '../pages/AgbPage'
import ImpressumPage from '../pages/ImpressumPage'
import EventDetailsPage from '../pages/EventDetailsPage'
import NotificationPage from '../pages/NotificationPage'
import FavouritePage from '../pages/FavouritePage'

class AppRoute extends Component {
  render() {
    return (
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<UserLoginPage />} />
          <Route path="/kontakt" element={<ContactPage />} />
          <Route path="/agb" element={<AgbPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />

          <Route path="/eventdetails" element={<EventDetailsPage />} />
          <Route path="/notification" element={<NotificationPage />} />
          <Route path="/favourite" element={<FavouritePage />} />
        </Routes>
    )
  }
}

export default AppRoute