import React, { Component } from 'react'

class MegaMenuMobile extends Component {

  constructor(){
    super();
    this.MegaMenu = this.MegaMenu.bind(this);
  }

  componentDidMount(){
    this.MegaMenu();
  }
  
  MegaMenu(){
    const acc = document.getElementsByClassName('accordionMobile');
    const accNum = acc.length;
    console.log(accNum);
    var i;
    console.log(acc);
    for(i=0;i<accNum;i++){
      acc[i].addEventListener('click',function(){
        //console.log('click');
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if(panel.style.maxHeight){
          panel.style.maxHeight = null;
        }else{
          panel.style.maxHeight = panel.scrollHeight+ "px"
        }
      })
    }
  }
  
  render() {
    return (
      <div className='accordionMenuDivMobile'>
        <div className='accordionMenuDivInsideMobile'>
          <button className='accordionMobile'>
            <i className='fa fa-home'> News</i>
          </button>
          <div className='panelMobile'>
            <ul>
              <li>
                <a href='/' className='accordionItemMobile'> Woche 1</a>
              </li>
              <li>
                <a href='/' className='accordionItemMobile'> Woche 2</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MegaMenuMobile