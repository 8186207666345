import React, { Component, Fragment } from 'react'
import { Container, Row, Card } from 'react-bootstrap'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class NewArrival extends Component {

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fragment>
        <Container className='text-center' fluid={true}>
          <div className='section-title text-center mb-55'>
            <h2>NEW ARRIVAL</h2>
            <p>Some Of Our Exclusive Collectionm You May Like</p>
          </div>
          <Row>
            <Slider ref={c=>(this.slider=c)} {...settings}>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 1
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 2
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 3
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 4
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 5
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 6
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 7
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 8
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </div>
            </Slider>
          </Row>

        </Container>
      </Fragment>
    )
  }
}

export default NewArrival