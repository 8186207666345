import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <Container>
          <Row className='p-2'>
            <Col className='ahadow-sm bg-white m-2' lg={12} md={12} sm={12} xs={12}>
              <Row className='text-center'>
                <Col className='d-flex justify-content-center' lg={6} md={6} sm={12} xs={12}>
                  <Form className='onboardForm'>
                    <h4 className='section-title-login'>SCHREIBEN SIE UNS</h4>
                    <h6 className='section-sub-title'>Mit diesem Formular schicken Sie eine Mail an die Betreiber dieser Webseite.</h6>
                    <input className='form-control m-2' type="text" placeholder='Enter Mobile Number' />
                    <input className='form-control m-2' type="email" placeholder='Enter Email' />
                    <input className='form-control m-2' type="text" placeholder='Enter Your Message' />
                    <Button className='btn btn-block m-2 site-btn-login'>Absenden</Button>
                  </Form>
                
                </Col>
                <Col className='p-0 m-0 Desktop' lg={6} md={6} sm={6} xs={6}>
                  <h4>EMPFÄNGER:</h4>
                  <p className='section-title-contact'>
                  Flecken Coppenbrügge<br />
                  Schlossstraße 2<br />
                  31863 Coppenbrügge<br />
                  Reimar Rauer<br />
                  </p>
                  <iframe title='GoogleMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1083.7506060085464!2d9.547601873791901!3d52.1197186626179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba9734b89aae09%3A0x18b824b688e290ed!2zU2NobG_Dn3N0cmHDn2UgMiwgMzE4NjMgQ29wcGVuYnLDvGdnZQ!5e1!3m2!1sen!2sde!4v1683896495879!5m2!1sen!2sde" width="600" height="450" styles="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Contact