import React, { Component } from 'react'

class MegaMenuAll extends Component {
  constructor(){
    super();
    this.MegaMenu = this.MegaMenu.bind(this);
  }

  componentDidMount(){
    this.MegaMenu();
  }
  
  MegaMenu(){
    const acc = document.getElementsByClassName('accordionAll');
    const accNum = acc.length;
    console.log(accNum);
    var i;
    console.log(acc);
    for(i=0;i<accNum;i++){
      acc[i].addEventListener('click',function(){
        //console.log('click');
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if(panel.style.maxHeight){
          panel.style.maxHeight = null;
        }else{
          panel.style.maxHeight = panel.scrollHeight+ "px"
        }
      })
    }
  }
  render() {
    return (
      <div className='accordionMenuDivAll'>
        <div className='accordionMenuDivInsideAll'>
          <button className='accordionAll'>
            <i className='fa fa-home'> News</i>
          </button>
          <div className='panelAll'>
            <ul>
              <li>
                <a href='/' className='accordionItemAll'> Woche 1</a>
              </li>
              <li>
                <a href='/' className='accordionItemAll'> Woche 2</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MegaMenuAll