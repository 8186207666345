import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaypalLogo from '../../assets/images/PayPal.svg'

class FooterDesktop extends Component {
  render() {
    return (
      <Fragment>
        <div className='footerback shadow-sm m-0 mt-5 pt-3'>
          <Container>
            <Row className='px-0 my-5'>
              <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                <h5 className='footer-menu-title'>GEMEINE ADRESSEN</h5>
                <p>
                  Flecken Coppenbrügge<br />
                  Schlossstraße 2<br />
                  31863 Coppenbrügge<br />                  
                  <Link to='/agb' className='footer-link'>AGB's</Link><br />
                  <Link to='/impressum' className='footer-link'>Impressum</Link>
                </p>
              </Col>
              <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                <h5 className='footer-menu-title'>KONTAKT</h5>
                <p>
                  Telefon:	05156 / 7819 0<br />
                  Telefax:	05156 / 78 19 40<br />
                </p>
                E-mail:
                <ul>
                  <li>rauer@coppenbruegge.de</li>
                  <li>flecken@coppenbruegge.de</li>
                </ul>
                <Link to='/kontakt' className='footer-link'>WEB-Kontakt</Link>

              </Col>

              <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
              <h5 className='footer-menu-title'>SOCIAL LINKS</h5>
              <p>
                <a href='/'><i className='fab m-1 h4 fa-facebook'></i></a>
                <a href='/'><i className='fab m-1 h4 fa-instagram'></i></a>
                <a href='/'><i className='fab m-1 h4 fa-twitter'></i></a>
              </p>
              </Col>

              

              <Col className='p-2' lg={3} md={3} sm={6} xs={12}>
                <h5 className='footer-menu-title'>INFO</h5>
                <p>
                  <a href='/'><img className='w-25' src={PaypalLogo} alt='paypal-Logo'/></a>
                </p>
              </Col>            
            </Row>
          </Container>
          <Container fluid={'true'} className='text-center m-0 pt-3 pb-1 bg-dark'>
            <Row>
              <h6 className='text-white'>© Copyright 2006 - 2023 by NET-COM, All Rights Reserved</h6>
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }
}

export default FooterDesktop