import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

class Categories extends Component {
  render() {
    return (
      <Fragment>
        <Container className='text-center' fluid={true}>
          <div className='section-title text-center mb-55'>
            <h2>Categiories</h2>
            <p>Some Of Our Exclusive Collectionm You May Like</p>
          </div>

        </Container>
        <Row>
          <Col key={1} xl={6} lg={6} md={2} sm={12} xs={12}>
            <Row>
              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 1
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>

              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 2
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>

              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 3
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>

              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 4
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>


          <Col key={1} xl={6} lg={6} md={2} sm={12} xs={12}>
            <Row>
              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 1
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>

              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 2
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>

              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 3
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>

              <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className='h-100 w-100 text-center'>
                  <Card.Body>
                    <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                    <h4 className='categorie-name'>
                      Woche 4
                    </h4>
                    <h5> 27.08. - 02.09.</h5>                    
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>


    )
  }
}

export default Categories