import React, { Component, Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'

class Agb extends Component {
  render() {
    return (
      <Fragment>
        <Container>
        <Row>          
          <div className="section-title">
            <h2 className='mb-5'> Allgemeine Geschäftsbedingungen</h2>  
              <ol>
                  <li>
                      <h4>Geltungsbereich</h4>
                      <p>
                      Für alle Bestellungen über unseren Online-Shop gelten die nachfolgenden AGB.
                      </p>
                  </li>

                  <li>
                      <h4>Einverständnisserklärung</h4>
                      <p>
                          Sollte ich mit der Veröffentlichung von Bildern meines Kindes im Rahmen von
                          Presseartikeln und/oder auf der Homepage der Jugendpflege über einzelne
                          Aktionen nicht einverstanden sein, teile ich dies dem jeweiligen Veranstalter vor Ort mit.

                          Mit der Bezahlung, des für die Veranstaltung angegebenen Kostenbeitrages,
                          bestätige ich die Teilnahmeerlaubins meines Kindes bei selbiger.
                      </p>
                  </li>

                  <li>
                      <h4>Schadensersatzanspruch und Haftungsausschluß</h4>
                      <p>
                          Für alle Fälle, in denen meine/unsere Tochter, mein/unser Sohn
                          im unmittelbaren oder mittelbaren Zusammenhang mit der Aktion entweder
                          einen Schaden erleidet oder Dritten einen Schaden zufügt, stelle ich die
                          Aufsichtspersonen und den Veranstalter von jeder Haftung frei,
                          sofern keine vorsätzliche oder grobfahrlässige Verletzung der Aufsichtspflicht vorliegt.
                      </p>
                  </li>
                  <li>
                      <h4>Vertragspartner, Vertragsschluss</h4>
                      <p>
                          Der Kaufvertrag kommt zustande mit Gemeinde Coppenbrügge.

                          Die Darstellung der Produkte im Online-Shop stellt kein rechtlich bindendes Angebot,
                              sondern einen unverbindlichen Online-Katalog dar. Sie können unsere Produkte zunächst
                              unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren,
                              indem Sie die hierfür im Bestellablauf vorgesehenen und erläuterten Korrekturhilfen nutzen.
                              Durch Anklicken des Bestellbuttons geben Sie eine verbindliche Bestellung der im Warenkorb enthaltenen Waren ab.
                              Die Bestätigung des Zugangs Ihrer Bestellung erfolgt per E-Mail unmittelbar nach dem Absenden der Bestellung.

                          Wann der Vertrag mit uns zustande kommt, richtet sich nach der von Ihnen gewählten Zahlungsart:

                          Paydirekt
                          Nach Abgabe der Bestellung werden Sie auf die Webseite des Online-Anbieters paydirekt weitergeleitet,
                              wo Sie die Zahlungsanweisung an paydirekt bestätigen.
                              Nach Abgabe der Bestellung fordern wir paydirekt zur Einleitung der Zahlungstransaktion auf und nehmen dadurch Ihr Angebot an.
                      </p>
                  </li>
                  <li>
                      <h4>Vertragssprache, Vertragstextspeicherung</h4>
                      <p>
                      Die für den Vertragsschluss zur Verfügung stehende Sprache ist Deutsch.
                      Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und unsere AGB per E-Mail zu.
                          Die AGB können Sie jederzeit auch hier auf dieser Seite einsehen und herunterladen.
                          Ihre vergangenen Bestellungen können Sie in unserem Kunden-Login einsehen.
                      </p>
                  </li>
                  <li>
                      <h4>Lieferbedingungen</h4>
                          <p>
                          Die von Ihnen gebuchten Aktionen werden zum vordefinierten Zeitraum durchgeführt.
                          Ein Nachholung einer Veranstaltung bei nicht erscheinen oder zu spätem Antritt ist ausgeschlossen.
                      </p>
                  </li>
                  <li>
                      <h4>Bezahlung</h4>
                      <p>
                      In unserem Shop stehen Ihnen grundsätzlich die folgenden Zahlungsarten zur Verfügung:
                      Paydirekt
                      Nach Abgabe der Bestellung werden Sie auf die Webseite des Online-Anbieters paydirekt weitergeleitet.
                          Um den Rechnungsbetrag über paydirekt bezahlen zu können, müssen Sie über ein für die Teilnahme an paydirekt freigeschaltetes Online-Banking-Konto verfügen,
                          dort registriert sein bzw. sich erst registrieren, mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung an uns bestätigen.
                      Unmittelbar nach der Bestellung fordern wir paydirekt zur Einleitung der Zahlungstransaktion auf.
                          Die Zahlungstransaktion wird durch paydirekt automatisch durchgeführt. Weitere Hinweise erhalten Sie beim Bestellvorgang.
                      </p>
                  </li>
                  <li>
                      <h4>Erstattungen</h4>
                      <p>
                          Erstattungen für gebuchte Veranstaltungen werden erst ab einem Erstattungsanspruch von <br/>
                          € 5,00 erfolgen, soweit diese Veranstaltungen nicht von der Jugendpflege oder dem ausrichtenden Verein abgesagt werden.
                      </p>
                  </li>
                  <li>
                      <h4>Eigentumsvorbehalt</h4>
                      <p>
                      Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.
                      </p>
                  </li>
                  <li>
                      <h4>Ausschlußvorbehalt</h4>
                      <p>
                      Sie sind verpflichtet korrekte Daten bei der Anmeldung zu hinterlegten.
                      Bei nicht korrekten Angaben behält die Gemeinde sich vor, ohne Erstattung der Kosten, den Teilnehmer von der Veranstaltung auszuschließen.
                      Eine Betreuung ist ab diesem Zeitpunkt seitens der Gemeinde nicht mehr gegeben.
                      </p>
                  </li>
                  <li>
                      <h4>Veranstaltungsrahmenzeit</h4>
                      <p>
                      Die Endzeiten der Veranstaltung können auf Grund unterschiedlicher Gegebenheiten nach vorne oder hinten variieren.
                      </p>
                  </li>
                  <li>
                      <h4>Gewährleistung und Garantien</h4>
                      <p>
                      Es gilt das gesetzliche Mängelhaftungsrecht. Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Onlineshop.
                      </p>
                  </li>
                  <li>
                      <h4>Streitbeilegung</h4>
                      <p>
                      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden http://ec.europa.eu/consumers/odr/.
                      Wir sind bereit, an einem außergerichtlichen Schlichtungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                      Zuständig ist die Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein, www.verbraucher-schlichter.de.
                      </p>
                  </li>
                  <li>
                      <h4>Jugendschutz</h4>
                      <p>
                      Sofern Ihre Bestellung Waren umfasst, deren Verkauf Altersbeschränkungen unterliegt, stellen wir durch den Einsatz eines zuverlässigen Verfahrens unter Einbeziehung einer persönlichen Identitäts- und Altersprüfung sicher, dass der Besteller das erforderliche Mindestalter erreicht hat. Der Zusteller übergibt die Ware erst nach erfolgter Altersprüfung und nur an den Besteller persönlich.
                      </p>
                  </li>
              </ol>
              AGB erstellt mit dem Trusted Shops Rechtstexter in Kooperation mit Wilde Beuger Solmecke Rechtsanwälte.
              </div>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Agb