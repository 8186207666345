import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class SuggestedEvent extends Component {
  render() {
    return (
      <Fragment>
        <Container className='text-center' fluid={true}>
          <div className='section-title text-center mb-55'>
            <h2>SIE KÖNNTEN AUCH INTERESSANT FINDEN</h2>
            <p>...</p>
          </div>
          <Row>
            <Col className='p-1 mt-10' key={1} xl={2} lg={2} md={3} sm={4} xs={6}>
              <Link to='/eventdetails'>
                <Card className='image-box card'>
                  <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                  <Card.Body>
                    <p className='product-name-on-card'>
                      Veranstaltung 1
                    </p>
                    <p className='product-price-on-card'>
                      Price : 5,00 €
                    </p>
                    
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 2
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 3
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 4
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 5
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 6
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 7
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 8
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 9
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className='p-1' xl={2} lg={2} md={3} sm={4} xs={6}>
              <Card className='image-box card'>
                <Card.Img className='center' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 10
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Container>

      </Fragment>
    )
  }
}

export default SuggestedEvent