import React, { Component, Fragment } from 'react'
import { Row, Col} from 'react-bootstrap'
import { Navbar, Container } from 'react-bootstrap';
import MainLogo from '../../assets/images/cube.svg';
import {Link} from 'react-router-dom'
import MegaMenuAll from '../home/MegaMenuAll';
import Menus_Bar from '../../assets/images/menus_bar.png';

class NavMenuDesktop extends Component {
  constructor(){
    super();
    this.state={
      SideNavState:'sideNavClose',
      ContentOverlayState:'ContentOverlayClose'
    }
  }

  MenuBarClickHandler=()=>{
    this.SideNavOpenClose();

  }

  ContentOverlayClickHandler=()=>{
    this.SideNavOpenClose();

  }

  SideNavOpenClose=()=>{
    let SideNavState = this.state.SideNavState;
    //let ContentOverlayState = this.state.ContentOverlayState
    if(SideNavState==='sideNavOpen'){
      this.setState({
        SideNavState:'sideNavClose',
        ContentOverlayState:'ContentOverlayClose'
      })
    }else{
      this.setState({
        SideNavState:'sideNavOpen',
        ContentOverlayState:'ContentOverlayOpen'
      })
    }

  }
  render() {
    return (
      <Fragment>
        <div className='TopSectionDown'>
          <Navbar className='navbar' fixed={'top'} bg="light">
            <Container fluid={'true'} className="fixed-top shadow-sm p-2 mb-0 bg-white">
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  
                  <img className='bar-img' onClick={this.MenuBarClickHandler} src={Menus_Bar} alt='Menu bar icon' />
                  <Navbar.Brand href="/"><img className='nav-logo' src={MainLogo} alt='Logo'/></Navbar.Brand>
                </Col>
                <Col className='p-1 mt-1' lg={4} md={4} sm={12} xs={12}>
                  <div className='input-group w-100'>
                    <input type='text' className='form-control' />
                    <button type='button' className='btn site-btn'><i className='fa fa-search'></i></button>
                  </div>
                  <Link to='/'>Home</Link>
                </Col>
                <Col className='p-1 mt-1' lg={4} md={4} sm={12} xs={12}>
                  <Link to='/favourite' className='btn'><i className='fa h4 fa-heart'><sup><span className='badge text-white bg-danger'>3</span></sup></i></Link>
                  <Link to='/notification' className='btn'><i className='fa h4 fa-bell'><sup><span className='badge text-white bg-danger'>5</span></sup></i></Link>
                  <a className='btn' href='/'><i className='fa h4 fa-mobile-alt'></i></a>
                  <Link to='/login' className='h4 btn'>Login</Link>
                  <button className='cart-btn'><i className='fa fa-shopping-cart'></i> 3 Items</button>
                </Col>
              </Row>

              
            </Container>
            
          </Navbar>
        </div>
        <div className={this.state.SideNavState}>
          <MegaMenuAll />             
        </div>
        <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverlayState}>

        </div>

      </Fragment>
    )
  }
}

export default NavMenuDesktop