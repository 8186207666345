import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

class Favourite extends Component {
  render() {
    return (
      
      <Fragment>
        <Container className='text-center' fluid={true}>
          <div className='section-title text-center mb-55'>
            <h2>MY FAVOURITE EVENTS</h2>
            <p>Some Of Our Exclusive Collectionm You May Like</p>
          </div>
          <Row>
            <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
              <Card className='image-box card w-100'>
                <Card.Img className='center w-75' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 1
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  <Button className='btn btn-sm'><i className='fa fa-trash-alt'> remove</i></Button>
                  
                </Card.Body>
              </Card>            
            </Col>
            <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
              <Card className='image-box card w-100'>
                <Card.Img className='center w-75' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 1
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  <Button className='btn btn-sm'><i className='fa fa-trash-alt'> remove</i></Button>
                  
                </Card.Body>
              </Card>            
            </Col>
            <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
              <Card className='image-box card w-100'>
                <Card.Img className='center w-75' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 1
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  <Button className='btn btn-sm'><i className='fa fa-trash-alt'> remove</i></Button>
                  
                </Card.Body>
              </Card>            
            </Col>
            <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
              <Card className='image-box card w-100'>
                <Card.Img className='center w-75' variant="top" src="img/logo_copp.png" />
                <Card.Body>
                  <p className='product-name-on-card'>
                    Veranstaltung 1
                  </p>
                  <p className='product-price-on-card'>
                    Price : 5,00 €
                  </p>
                  <Button className='btn btn-sm'><i className='fa fa-trash-alt'> remove</i></Button>
                  
                </Card.Body>
              </Card>            
            </Col>
            
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Favourite